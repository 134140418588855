'use client'
import { useTranslation } from '@/lib/locales/client'

export default function NotFoundPage() {
  const { t } = useTranslation()

  return (
    <div title={t('addToHome.title')}>
      <p className="max-w-[460px]">{t('addToTome.content')}</p>
    </div>
  )
}
